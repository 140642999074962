import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'
import theme from './VideoThumbnail.module.scss'
import { FiPlayCircle as PlayIcon } from 'react-icons/fi';

class VideoThumbnail extends PureComponent {
 render() {
  const {
   imageUrl,
   title,
   description,
   subheader,
   className,
   tags,
   isCourse = false,
  } = this.props

  return (
   <div className={cn(theme.VideoThumbnail, className)}>
    {imageUrl && (
     <img className={theme.VideoThumbnail_Image} src={imageUrl} alt='video thumbnail' />
    )}
    <div className={theme.VideoThumbnail_Container}>
     <h4 className={theme.VideoThumbnail_Title}>{title}</h4>

     {description && (
      <p className={theme.VideoThumbnail_Description}>{description}</p>
     )}
     {tags && (
      <div className={theme.VideoThumbnail_TagContainer}>
       {tags.split(',').map(tag => <div className={theme.VideoThumbnail_Tag}>{tag}</div>)}
      </div>
     )}
     {isCourse && (
      <div className={theme.VideoThumbnail_Tag}>{"Free Course"}</div>
     )}
     <Link to='/' className={theme.VideoThumbnail_WatchLink}>
      <PlayIcon className={theme.VideoThumbnail_Icon} />
      Watch Video
     </Link>
    </div>
   </div>
  )
 }
}

export default VideoThumbnail
