import React from "react"
import get from 'lodash/get'
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MenuToolbar from "../components/MenuToolbar"
import VideoThumbnail from 'components/ui/VideoThumbnail'
import theme from './video.module.scss'
import urlManager from 'lib/utils/urlManager'

const getTutorials = videos => {
  console.log(videos)
  return (
    videos.map((video, i) => {
      const { frontmatter: post } = video.node
      const { type, title, page } = post
      return (
        <Link
          key={i}
          to={urlManager.buildSEOpathForContent(type, title, page)}
          className={theme.Video_Link}
        >
          <VideoThumbnail
            imageUrl={get(video, 'node.frontmatter.imageUrl.path.fixed.src', '')}
            title={video.node.frontmatter.title}
            subheader='An introduction to Angular'
            tags={get(video, 'node.frontmatter.tags', [])}
            description='Learn about Angular and how to build beginner apps'
            isCourse={true}
            actionButton={{
              title: 'Enroll',
              onClick: () => { alert('hello world!') }
            }}
          />
        </Link>
      )
    })
  )
}

const VideoPage = ({ data }) => {
  const { edges: videos } = data.allMarkdownRemark
  const AllVideos = getTutorials(videos)
  return (
    <>
      <SEO title="Search" keywords={[
        `Angular`,
        `React`,
        `JavaScript`,
        `Code Bootcamp`,
        `Web Tutorials`,
        `Startup Training`
      ]} />
      <MenuToolbar />
      <Layout>
        <div className={theme.Video_Container}>
          {AllVideos}
        </div>
      </Layout>
    </>
  )
}

export default VideoPage

export const pageQuery = graphql`
  query VideoQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter:{ type:{eq:"video"}}}
      ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            tags,
            page
            author
            title
            type
            imageUrl{
              path: childImageSharp{
                fixed(width: 300, height: 125) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`